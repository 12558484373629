import { Component } from '@angular/core';

@Component({
  selector: 'app-relationship-offset-amount-info',
  templateUrl: './relationship-offset-amount-info.component.html',
  styleUrls: ['./relationship-offset-amount-info.component.scss']
})
export class RelationshipOffsetAmountInfoComponent {

}
