import { Component } from '@angular/core';

@Component({
  selector: 'app-spouse-dob-info',
  templateUrl: './spouse-dob-info.component.html',
  styleUrls: ['./spouse-dob-info.component.scss']
})
export class SpouseDobInfoComponent {

}
