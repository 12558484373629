<app-expansion-panel [(panelOpenState)]="appService.welcomeExpanded" [enabled]="true">
  <div class="panel_header" header>
    <img src="../../../images/AMA_icon_intro.svg" />
    <h1>
      Welcome, we're glad you're here!
    </h1>
  </div>
  <div class="content">
    <div class="text-wrap">
      <p>
        This tool will illustrate what your pension income could look like so
        you can plan for retirement confidently.
      </p>
      <!-- <ul>
        <li><b>STAY</b> in the current pension plan</li>
        OR
        <li><b>SWITCH</b> to the new Group RRSP</li>
      </ul> -->
      <div class="report-image-wrapper">
        <!-- <img src="../images/AMA Report Images.svg" target="_blank" /> -->
      </div>
      <h3>How does it work?</h3>

      <p>To get started, you'll need to provide a few pieces of information:</p>

      <ul>
        <li>
          <b>First</b>, you’ll need to enter some basic personal information in
          the section called
          <i class="color-blue">Your personal data.</i>
        </li>
        <li>
          <b>Then</b>, move on to the section called
          <i class="color-blue">Your statement data</i>. This is where you’ll
          enter information that’s found on most recent annual pension
          statement.
        </li>
        <li>
          <b>Next</b>, you’ll set some assumptions about the future in the
          section called <i class="color-blue">Your assumptions</i>.
        </li>
      </ul>

      <div class="callout-banner-spacing">
        <div class="callout-banner">
          <p>
            You can find the information you need to use this tool in your <strong> annual Pension Plan Statement
              booklet, mailed to your home each year in June. </strong>
          </p>
        </div>
      </div>
      <p>
        <strong>And then the magic happens.</strong>
      </p>
      <p>
        See what your potential income looks like in the DB pension plan, including:
      </p>
      <ul>
        <li>
          Your estimated annual income at your chosen retirement age (view 1)
        </li>
        <li>
          Your estimated annual income for all potential retirement ages (view
          2)
        </li>
      </ul>
    </div>

    <p>
      You can change your assumptions along the way if you want to see different
      scenarios, but bear in mind that your data is <strong>not</strong> saved in the tool. You can print any scenario
      if you’d like to keep a record
      of it – just click or tap the
      <span class="color-blue"> <strong> Print </strong></span> button.
    </p>
    <app-hint>
      We suggest printing a few scenarios and bringing them to your financial
      planner so you can walk through the results together.</app-hint>
    <p>
      Keep in mind, this tool gives you estimates based on assumptions and the
      information you entered. The numbers you see are meant to help you
      plan for retirement, but you shouldn’t take them as guarantees. (There are so many
      factors that can affect the actual income you’ll receive when it’s time to
      retire.) This tool will give you a reasonable idea of what you can expect.
    </p>
    <div class="disclaimer-wrapper">
      <app-notice header="Disclaimer">
        <p>
          This tool's results are estimates based on the information that you input. Some of the assumptions may differ
          from
          your personal situation. The results should not be considered financial advice. You are welcome to speak to a
          financial
          planner or other advisor at any time about your options. As well, this tool is provided "as is", and neither
          AMA nor any
          of its contractors are responsible for any defects or errors.
        </p>
      </app-notice>
    </div>
    <p>
      <strong>
        We hope this tool helps you
        <i class="color-blue">plan for retirement confidently</i>.</strong>
    </p>
    <mat-checkbox [(ngModel)]="agreedToTerms" [checked]="agreedToTerms" [disabled]="appService.personalDataEnabled"
      class="noprint">
      <span class="mat-checkbox-label" style="padding-top: 10px; padding-bottom: 40px;"
        (mousedown)="$event.stopPropagation()" (click)="check($event)">
        I confirm that I have read the
        <a (click)="openTermsAndConditionsDialog()"><u>Terms & Conditions</u></a>
        for this tool. I understand that it is for illustration and information
        purposes only, and that actual outcomes, being the funds available at my
        retirement, may vary based on future economic conditions and other
        unknown variables. I will not hold AMA liable for any decisions made
        based on the information provided by this tool. I understand that I am encouraged to use other financial
        planning tools that are available or to get the advice of my accountant or other qualified advisor.
      </span>
    </mat-checkbox>

    <button mat-flat-button (click)="getStartedClick()" [disabled]="!agreedToTerms" color="primary">
      Get Started!
    </button>
  </div>
</app-expansion-panel>