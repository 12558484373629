import { Component } from '@angular/core';

@Component({
  selector: 'app-credited-service-info',
  templateUrl: './credited-service-info.component.html',
  styleUrls: ['./credited-service-info.component.scss']
})
export class CreditedServiceInfoComponent {

}
