import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PersonalDataComponent } from './features/personal-data/personal-data.component';
import { StatementDataComponent } from './features/statement-data/statement-data.component';
import { AssumptionsComponent } from './features/assumptions/assumptions.component';
import { ComparisonResultsComponent } from './features/comparison-results/comparison-results.component';
import { NextStepsComponent } from './features/next-steps/next-steps';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { FooterComponent } from './features/footer/footer.component';
import { ExpansionPanelComponent } from './features/expansion-panel/expansion-panel.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeatmapChartComponent } from './features/heatmap-chart/heatmap-chart.component';
import { StackedBarChartComponent } from './features/stacked-bar-chart/stacked-bar-chart.component';
import { NoticeComponent } from './features/notice/notice.component';
import { MatCardModule } from '@angular/material/card';
import { HintComponent } from './features/hint/hint.component';
import { ViewHeaderComponent } from './features/view-header/view-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';
import {  MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ResultsDialogComponent } from './features/results-dialog/results-dialog.component';
import { StatementDialogsComponent } from './features/statement-dialogs/statement-dialogs.component';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {
  NgxCurrencyDirective,
  NgxCurrencyInputMode,
  provideEnvironmentNgxCurrency,
} from 'ngx-currency';
import { InfoDialogComponent } from './features/dialogs/info-dialog/info-dialog.component';
import { IncomeTableComponent } from './features/income-table/income-table.component';
import { TermsAndConditionsComponent } from './features/dialogs/terms-and-conditions/terms-and-conditions.component';
import { ComponentDialogComponent } from './features/dialogs/component-dialog/component-dialog.component';
import { WelcomeComponent } from './features/welcome/welcome.component';
import { DobInfoComponent } from './features/dialogs/personal-data/dob-info/dob-info.component';
import { SpouseInfoComponent } from './features/dialogs/personal-data/spouse-info/spouse-info.component';
import { SpouseDobInfoComponent } from './features/dialogs/personal-data/spouse-dob-info/spouse-dob-info.component';
import { JoinDateInfoComponent } from './features/dialogs/statement-data/join-date-info/join-date-info.component';
import { CreditedServiceInfoComponent } from './features/dialogs/statement-data/credited-service-info/credited-service-info.component';
import { PensionableEarningsInfoComponent } from './features/dialogs/statement-data/pensionable-earnings-info/pensionable-earnings-info.component';
import { DbPensionInfoComponent } from './features/dialogs/statement-data/db-pension-info/db-pension-info.component';
import { DeferredPensionInfoComponent } from './features/dialogs/statement-data/deferred-pension-info/deferred-pension-info.component';
import { DeferredPensionAmountInfoComponent } from './features/dialogs/statement-data/deferred-pension-amount-info/deferred-pension-amount-info.component';
import { RelationshipOffsetFlagInfoComponent } from './features/dialogs/statement-data/relationship-offset-flag-info/relationship-offset-flag-info.component';
import { RelationshipOffsetAmountInfoComponent } from './features/dialogs/statement-data/relationship-offset-amount-info/relationship-offset-amount-info.component';
import { SalaryGrowthInfoComponent } from './features/dialogs/assumptions/salary-growth-info/salary-growth-info.component';
import { RrspContributionInfoComponent } from './features/dialogs/assumptions/rrsp-contribution-info/rrsp-contribution-info.component';
import { InvestmentRiskInfoComponent } from './features/dialogs/assumptions/investment-risk-info/investment-risk-info.component';
import { DisclaimerComponent } from './features/dialogs/assumptions/disclaimer/disclaimer.component';
import { NewGroupRrspInfoComponent } from './features/dialogs/comparison/new-group-rrsp-info/new-group-rrsp-info.component';
import { CurrentPensionPlanInfoComponent } from './features/dialogs/comparison/current-pension-plan-info/current-pension-plan-info.component';
import { DialogPrintDumpComponent } from './features/dialogs/dialog-print-dump/dialog-print-dump.component';
import { LearnMoreInfoComponent } from './features/dialogs/learn-more-info/learn-more-info.component';
import { PlannedRetirementInfoComponent } from './features/dialogs/assumptions/planned-retirement-info/planned-retirement-info.component';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { UnderMaintenanceComponent } from './features/under-maintenance/under-maintenance.component';
import { AnnualPensionStatementYearInfoComponent } from './features/dialogs/statement-data/annual-pension-statement-year-info/annual-pension-statement-year-info.component';
@NgModule({
  declarations: [
    AppComponent,
    PersonalDataComponent,
    StatementDataComponent,
    AssumptionsComponent,
    ComparisonResultsComponent,
    NextStepsComponent,
    FooterComponent,
    ExpansionPanelComponent,
    HeatmapChartComponent,
    StackedBarChartComponent,
    NoticeComponent,
    HintComponent,
    ViewHeaderComponent,
    ResultsDialogComponent,
    StatementDialogsComponent,
    InfoDialogComponent,
    IncomeTableComponent,
    TermsAndConditionsComponent,
    ComponentDialogComponent,
    WelcomeComponent,
    DobInfoComponent,
    SpouseInfoComponent,
    SpouseDobInfoComponent,
    JoinDateInfoComponent,
    CreditedServiceInfoComponent,
    PensionableEarningsInfoComponent,
    DbPensionInfoComponent,
    DeferredPensionInfoComponent,
    DeferredPensionAmountInfoComponent,
    RelationshipOffsetFlagInfoComponent,
    RelationshipOffsetAmountInfoComponent,
    SalaryGrowthInfoComponent,
    RrspContributionInfoComponent,
    InvestmentRiskInfoComponent,
    DisclaimerComponent,
    NewGroupRrspInfoComponent,
    CurrentPensionPlanInfoComponent,
    DialogPrintDumpComponent,
    LearnMoreInfoComponent,
    PlannedRetirementInfoComponent,
    PageNotFoundComponent,
    CalculatorComponent,
    UnderMaintenanceComponent,
    AnnualPensionStatementYearInfoComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatExpansionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    NgxCurrencyDirective,
    HttpClientModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    DatePipe,
    provideEnvironmentNgxCurrency({
      align: "left",
      allowNegative: true,
      allowZero: true,
      decimal: ".",
      precision: 2,
      prefix: "$ ",
      suffix: "",
      thousands: ",",
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Natural,
    }),
    
  ],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
