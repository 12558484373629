<p>
  Your earnings are used to estimate your pension income under the Defined Benefit pension
  plan. The tool starts with your most recent annualized pensionable earnings and, using a salary
  growth assumption chosen by you, projects your earnings for future years.
</p>
<p>
  You can find your <b>Annualized pensionable earnings for the most recent statement year</b> in your Annual Pension
  Statement:
</p>
<div class="dialog-image-wrapper">
  <img src="../../../images/pensionable-earnings-dialog.png" alt="" />
</div>