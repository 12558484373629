import { Component } from '@angular/core';

@Component({
  selector: 'app-db-pension-info',
  templateUrl: './db-pension-info.component.html',
  styleUrls: ['./db-pension-info.component.scss']
})
export class DbPensionInfoComponent {

}
