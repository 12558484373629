<main>
  <div class="header">
    <img src="../images/banner.png" alt="" class="noprint" />
  </div>
  <div class="content">
    <mat-accordion multi>
      <div class="page-break-after">
        <app-welcome></app-welcome>
      </div>
      <div class="page-break-after">
        <app-personal-data></app-personal-data>
        <app-statement-data></app-statement-data>
      </div>
      <div class="page-break-after">
        <app-assumptions></app-assumptions>
      </div>
      <div class="page-break-before">
        <app-comparison-results></app-comparison-results>
      </div>
      <div class="page-break-before">
        <app-next-steps></app-next-steps>
      </div>
    </mat-accordion>
    <app-dialog-print-dump class="print"></app-dialog-print-dump>
  </div>

  <div class="footer-container"><app-footer></app-footer></div>
</main>