<app-expansion-panel
  [(panelOpenState)]="appService.personalDataExpanded"
  [enabled]="appService.personalDataEnabled"
>
  <div class="panel_header" header>
    <img src="../../../images/AMA_icon_personaldata.svg" />
    <h1>Your personal data</h1>
  </div>
  <p class="noprint">*All fields are required</p>
  <form [formGroup]="personalDataFG" #form="ngForm" (ngSubmit)="next()">
    <label class="info"
      >Your month & year of birth:<img
        src="../images/icon.svg"
        (click)="openDOBInfoDialog()"
    /></label>
    <mat-form-field>
      <mat-label>Your month of birth</mat-label>
      <mat-select required formControlName="birthMonth">
        <mat-option *ngFor="let month of months" [value]="month">{{
          month
        }}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="personalDataFG.controls.birthMonth.hasError('required')"
      >
        *Required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Your year of birth</mat-label>
      <input matInput type="number" formControlName="birthYear" />
      <mat-error
        *ngIf="
          personalDataFG.controls.birthYear.hasError('min') ||
          personalDataFG.controls.birthYear.hasError('max')
        "
      >
        Your year of birth must be between {{ minBirthYear }} &
        {{ maxBirthYear }}
      </mat-error>
      <mat-error *ngIf="personalDataFG.controls.birthYear.hasError('required')">
        *Required
      </mat-error>
    </mat-form-field>
    <label class="info" *ngIf="isOlderThan55"
      >Do you expect to have a spouse at retirement?<img
        src="../images/icon.svg"
        (click)="openSpouseInfoDialog()"
    /></label>
    <mat-radio-group *ngIf="isOlderThan55"
      aria-label="Marital Status"
      formControlName="maritalStatus"
    >
    <mat-radio-button [value]="MaritalStatus.Married">Yes</mat-radio-button>
    <mat-radio-button [value]="MaritalStatus.Single">No</mat-radio-button>
    </mat-radio-group>
    <label
      *ngIf="personalDataFG.controls['maritalStatus'].value! === MaritalStatus.Married && isOlderThan55"
      class="info"
      >Your spouse’s month & year of birth:
      <img src="../images/icon.svg" (click)="openSpouseDOBInfoDialog()"
    /></label>
    <mat-form-field
      *ngIf="personalDataFG.controls['maritalStatus'].value! === MaritalStatus.Married && isOlderThan55"
    >
      <mat-label>Your spouse’s month of birth</mat-label>
      <mat-select required formControlName="birthMonthSpouse">
        <mat-option *ngFor="let month of months" [value]="month">{{
          month
        }}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="personalDataFG.controls.birthMonthSpouse.hasError('required')"
      >
        *Required
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="personalDataFG.controls['maritalStatus'].value! === MaritalStatus.Married && isOlderThan55"
    >
      <mat-label>Your spouse’s year of birth</mat-label>
      <input matInput type="number" formControlName="birthYearSpouse" />
      <mat-error
        *ngIf="
          personalDataFG.controls.birthYearSpouse.hasError('min') ||
          personalDataFG.controls.birthYearSpouse.hasError('max')
        "
      >
        Your year of birth must be between {{ minBirthYear }} &
        {{ maxBirthYear }}
      </mat-error>
      <mat-error
        *ngIf="personalDataFG.controls.birthYearSpouse.hasError('required')"
      >
        *Required
      </mat-error>
    </mat-form-field>
    <div class="break"></div>
    <button
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="!personalDataFG.valid"
    >
      {{ !form.submitted ? 'Save & Continue' : 'Update & Continue' }}
    </button>
  </form>
</app-expansion-panel>

