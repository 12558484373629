<div class="table-container">
  <table id="income">
    <!-- <tr>
      <th colspan="2" class="foo-header">Projected annual income at your chosen risk level ({{ risk }})</th>
    </tr> -->
    <tr>
      <th>Retirement age</th>
      <th>DB pension plan</th>
      <!-- <th>New Group RRSP plan (AMA funded)</th>
      <th>New Group RRSP plan (Funded by you)</th>
      <th>New Group RRSP plan TOTAL</th> -->
    </tr>
    <tr *ngFor="let item of filteredResults">
      <td>{{ item.fractionalAge }} years</td>
      <td>${{ item.dbAnnualPension | number }}</td>
      <!-- <td>${{ item.dcAnnualPensionFromEr | number}}</td>
      <td>${{ item.dcAnnualPensionFromEe | number }}</td>
      <td>${{ item.dcAnnualPensionTotal | number }}</td> -->
    </tr>
  </table>
</div>