import { Component } from '@angular/core';

@Component({
  selector: 'app-current-pension-plan-info',
  templateUrl: './current-pension-plan-info.component.html',
  styleUrls: ['./current-pension-plan-info.component.scss']
})
export class CurrentPensionPlanInfoComponent {

}
