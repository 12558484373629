import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-print-dump',
  templateUrl: './dialog-print-dump.component.html',
  styleUrls: ['./dialog-print-dump.component.scss']
})
export class DialogPrintDumpComponent {

}
