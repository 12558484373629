<div class="dialog">
  <h2>Terms & Conditions</h2>
  <mat-dialog-content>
    <b>Welcome!</b>
    <p>
      This is our “Terms and Conditions” page. We know it isn't everyone's favourite thing to read, but there’s a lot of
      important information here. We've done our best to make it easy to navigate so you can find what you need. While
      we do sometimes have to use legal language, we've also tried to make it easy to understand (and if you have any
      questions, you can always contact us!). We’re practically family and we believe an important part of that is
      making this information accessible to you.
    </p>
    <b>Terms and Conditions of Use</b>
    <p>
      Your access to the Alberta Motor Association (“AMA”) Plan Comparison Estimator (the “Estimator”) is subject to
      these Terms
      and Conditions as well as all applicable laws (collectively the “Terms”). By accessing and using the Estimator,
      you
      accept these Terms without limitation or qualification. If you are not willing to accept these Terms and the AMA
      Employee Privacy Policy, you will immediately stop using the Estimator. AMA may at any time revise these Terms.
      You are
      bound by such revisions and should therefore visit this page to review the current Terms from time to time.
    </p>
    <p>
      AMA may modify or discontinue the Estimator or any part of the Estimator without notice at any time or refuse
      access to the
      Estimator to anyone for any reason at any time. AMA shall not be liable to you or to any third-party for any such
      modification, suspension, discontinuance, or refusal of access to the Estimator. AMA does not warrant that the
      Estimator
      will meet your expectations or that any errors on the Estimator or Estimator content will be corrected.
    </p>
    <b>General</b>
    <p>
      The Estimator is not a secure medium for email communications. If you wish to provide information of a
      confidential or
      sensitive nature, please contact Human Resources directly by telephone.
    </p>
    <p>
      You are responsible for obtaining and maintaining all necessary equipment, software and communication links as
      required to access the Estimator.
    </p>
    <p>
      When using the Estimator, it is your responsibility to take reasonable precautions to scan for computer viruses
      and
      other items of a destructive nature. You should ensure to have a complete and current backup of the information on
      your computer system prior to using the Estimator.
    </p>
    <b> Disclaimer and Limitation of Liability </b>
    <p>
      Return projections used by the Estimator are hypothetical in nature, provided solely for informational purposes
      and do
      not constitute a commitment by AMA.
    </p>
    <p>
      The results provided by the Estimator are rough estimates based on the information you provided and should not be:
      1)
      considered financial advice; 2) relied upon as accurate, complete, timely or fit for any particular purpose; or 3)
      used as the sole basis for making decisions without consulting primary, more accurate, more complete or more
      timely sources of information.
    </p>
    <p>
      You are advised to seek specific advice by contacting your own financial planner or other advisor about your
      options in relation to your specific issues. This Estimator may contain certain historical information. Historical
      information is not current and is provided for your reference only.
    </p>
    <p>
      The features and functionality made available on the Estimator are provided “AS IS” with all faults. AMA expressly
      disclaims any liability for losses or damages, whether direct, indirect, specific or consequential incurred by
      you, including but not limited to loss of revenue or profits, and whether by contract, negligence or otherwise. In
      consideration for AMA providing you access to the Estimator, you shall release AMA and its respective directors,
      officers, employees and agents from all claims and proceedings for such losses, damages or consequences.
    </p>
    <b> Privacy and Personal Information </b>
    <p>
      Use of the Estimator is subject to the <a (click)="openPrivacyPolicyPage()"><u>AMA Employee Privacy
          Policy</u></a>.
    </p>
    <p>
      Information entered in the Estimator is used only for calculations within the Estimator. The Estimator does not
      collect or save
      the data you provide.
    </p>
    <b> Personal Use Only </b>
    <p>
      You agree that you will not, without our prior written permission, use the Estimator and the Estimator content for
      purposes
      other than your own personal non-commercial use and benefit. You acquire absolutely no rights or licenses to the
      Estimator or Estimator content other than the limited right to use the Estimator in accordance with these Terms.
    </p>
    <b>Shared technology</b>
    <p>
      If you are using a shared computer or working from a public place such as a library, you should ensure that you
      shut down the browser once you have completed your session so that the information provided by you is not
      accessible to other individuals who may subsequently use the same computer.
    </p>
    <p>
      You must also be cautious about saving any personal information on the hard drive, which would also become
      accessible to other individuals.
    </p>
    <p>
      Another word of caution is to ensure that you also delete any outstanding print jobs when you conclude your
      session so that none of your personal information can subsequently be printed and be made available to other
      persons.
    </p>
    <b>Design and Content</b>
    <p>
      The design and content of this Estimator is copyrighted. All rights reserved. No use of the Estimator, or features
      of the
      Estimator, in any manner grants any intellectual property right to any other party. Reproduction of the design or
      any
      images used herein is strictly prohibited. For greater certainty and the avoidance of any doubt, you agree that
      you will not take any action that is inconsistent with AMA’s ownership of the Estimator and/or any Estimator
      content.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">
      Close
    </button>
  </mat-dialog-actions>
</div>