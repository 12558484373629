import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';
import { AppService } from '../services/app.service';
import { MatAccordion } from '@angular/material/expansion';
import { CalculatorService
 } from '../services/calculator.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  title = 'ama-calculator';
  public agreedToTerms: boolean = false;
  public print: boolean = false;

  constructor(public appService: AppService) {}
  ngAfterViewInit(): void {
    this.appService.printClick$.subscribe((result: boolean) => {
      this.accordion.openAll();
    });
  }

  @HostListener('window:afterprint')
  onbeforeprint() {
    if (this.appService.isPrint) {
      this.accordion.openAll();
    }
    this.appService.isPrint = false;
  }

  @HostListener('window:afterprint')
  onafterprint() {
    if (this.appService.isPrint) {
      this.appService.welcomeExpanded = false;
      this.appService.personalDataExpanded = false;
      this.appService.statementDataExpanded = false;
      this.appService.assumptionsExpanded = false;
      this.appService.comparisonExpanded = true;
    }
  }
}
