import { Platform } from '@angular/cdk/platform';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppService } from 'src/app/services/app.service';
import { MonthpickerDateAdapter } from '../monthpickerDateAdapter';
import { CalculatorService } from 'src/app/services/calculator.service';
import { PersonalData } from 'src/app/models/PersonalData';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { months } from 'src/app/Months';
import { MaritalStatus } from 'src/app/models/ComparisonInput';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { DobInfoComponent } from '../dialogs/personal-data/dob-info/dob-info.component';
import { SpouseDobInfoComponent } from '../dialogs/personal-data/spouse-dob-info/spouse-dob-info.component';
import { SpouseInfoComponent } from '../dialogs/personal-data/spouse-info/spouse-info.component';
@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class PersonalDataComponent implements OnInit, OnDestroy {
  @Input() isEnabled: boolean = false;
  public MaritalStatus = MaritalStatus;
  public months = months;
  public minBirthYear = new Date().getFullYear() - 70;
  public maxBirthYear = new Date().getFullYear() - 15;
  public isOlderThan55: boolean = false;
  public personalDataFG = new FormGroup({
    birthMonth: new FormControl<string | null>(null, Validators.required),
    birthYear: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(this.minBirthYear),
      Validators.max(this.maxBirthYear),
    ]),
    maritalStatus: new FormControl<MaritalStatus | null>(
      null,
      Validators.required
    ),
    birthMonthSpouse: new FormControl<string | null>(null, Validators.required),
    birthYearSpouse: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(this.minBirthYear),
      Validators.max(this.maxBirthYear),
    ]),
  });

  private maritalStatus$!: Subscription;
  private birthYear$!: Subscription;
  private birthMonth$!: Subscription;

  constructor(
    public appService: AppService,
    public calculatorService: CalculatorService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.birthYear$ =
      this.personalDataFG.controls.birthYear.valueChanges.subscribe(
        (birthYear: number | null) => {
          this.checkIsOlderThan55();
        }
      );

    this.birthMonth$ =
      this.personalDataFG.controls.birthMonth.valueChanges.subscribe(
        (birthMonth: string | null) => {
          this.checkIsOlderThan55();
        }
      );

    this.maritalStatus$ =
      this.personalDataFG.controls.maritalStatus.valueChanges.subscribe(
        (status: MaritalStatus | null) => {
          if (status === MaritalStatus.Married) {
            this.personalDataFG.controls.birthMonthSpouse.enable();
            this.personalDataFG.controls.birthYearSpouse.enable();
            this.personalDataFG.controls.birthMonthSpouse.markAsUntouched();
            this.personalDataFG.controls.birthYearSpouse.markAsUntouched();
          } else {
            this.personalDataFG.controls.birthMonthSpouse.setValue(null);
            this.personalDataFG.controls.birthYearSpouse.setValue(null);
            this.personalDataFG.controls.birthMonthSpouse.disable();
            this.personalDataFG.controls.birthYearSpouse.disable();
          }
        }
      );
  }

  ngOnDestroy(): void {
    if (this.maritalStatus$) this.maritalStatus$.unsubscribe();
    if (this.birthYear$) this.maritalStatus$.unsubscribe();
    if (this.birthMonth$) this.maritalStatus$.unsubscribe();
  }

  public next(): void {
    this.calculatorService.setPersonalData(
      <PersonalData>this.personalDataFG.getRawValue()
    );
    this.appService.personalDataExpanded = false;
    this.appService.statementDataEnabled = true;
    this.appService.statementDataExpanded = true;
  }

  public openDOBInfoDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Your month and year of birth',
        dialogContent: DobInfoComponent,
        width: '40vh',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public openSpouseDOBInfoDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Your expected spouse’s month & year of birth?',
        dialogContent:  SpouseDobInfoComponent,
        width: '40vh',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public openSpouseInfoDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Do you expect to have a spouse at retirement?',
        dialogContent: SpouseInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  private checkIsOlderThan55() {
    if (
      this.personalDataFG.controls.birthMonth.valid &&
      this.personalDataFG.controls.birthYear.valid
    ) {
      const birthMonth = this.personalDataFG.controls.birthMonth.value!;
      const birthYear = this.personalDataFG.controls.birthYear.value!;
      const months: { [key: string]: number } = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11,
      };

      const ageYear: number = new Date().getFullYear() - birthYear;
      if (ageYear < 55 || (ageYear === 55 && months[birthMonth] > 11))
        this.removeSouseValidators();
      else this.addSpouseValidators();
    } else this.isOlderThan55 = false;
  }

  private removeSouseValidators(): void {
    this.isOlderThan55 = false;
    this.personalDataFG.controls.maritalStatus.setValue(MaritalStatus.Single);
    this.personalDataFG.controls.birthMonthSpouse.setValidators(null);
    this.personalDataFG.controls.birthYearSpouse.setValidators(null);

    this.personalDataFG.controls.birthMonthSpouse.updateValueAndValidity();
    this.personalDataFG.controls.birthYearSpouse.updateValueAndValidity();
  }

  private addSpouseValidators(): void {
    this.isOlderThan55 = true;

    this.personalDataFG.controls.maritalStatus.setValue(null);
    this.personalDataFG.controls.birthMonthSpouse.setValidators(
      Validators.required
    );
    this.personalDataFG.controls.birthYearSpouse.setValidators([
      Validators.required,
      Validators.min(this.minBirthYear),
      Validators.max(this.maxBirthYear),
    ]);

    this.personalDataFG.controls.birthMonthSpouse.updateValueAndValidity();
    this.personalDataFG.controls.birthYearSpouse.updateValueAndValidity();
  }
}
