<p>
  Your credited service is used for key calculations under the current pension
  plan, such as whether you are eligible for an unreduced early retirement
  pension.
</p>
<p>
  You can find your <b>Total credited service as of statement year</b> in your Annual Pension Statement:
</p>
<div class="dialog-image-wrapper">
  <img src="../../../images/total-credited-dialog.png" alt="" />
</div>