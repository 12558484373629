import { catchError, take } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComparisonResult } from 'src/app/models/ComparisonResult';
import { AppService } from 'src/app/services/app.service';
import { CalculatorService } from 'src/app/services/calculator.service';
import { months } from 'src/app/Months';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { RiskTolerance } from 'src/app/models/ComparisonInput';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material/icon';
import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { throwError } from 'rxjs';
@Component({
  selector: 'app-results-dialog',
  templateUrl: './results-dialog.component.html',
  styleUrls: ['./results-dialog.component.scss'],
})
export class ResultsDialogComponent implements OnInit {
  public comparisonResult: ComparisonResult[] | null = null;
  public calculationInput!: CalculationInput;
  public RiskTolerance = RiskTolerance;
  public isCalculating: Boolean = false;
  public isErrorInCalc: Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResultsDialogComponent>,
    public calculatorService: CalculatorService,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    this.calculationInput = this.calculatorService.getCalculationInput();
  }

  public editPersonalData() {
    this.dialogRef.close('edit');
  }

  viewResults() {
    this.isCalculating = true;
    this.calculatorService
      .calculate()
      .pipe(
        take(1),
        catchError((err: any) => {
          this.isErrorInCalc = true;
          this.isCalculating = false;
          return throwError(err);
        })
      )
      .subscribe((result: ComparisonResult[]) => {
        this.calculatorService.updateComparisonResult(result);
        this.isErrorInCalc = false;
        this.isCalculating = false;
        this.dialogRef.close('compare');
      });
  }

  public isOlderThan55(){
    const months: { [key: string]: number } = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    if (this.calculationInput.personalData.birthYear && this.calculationInput.personalData.birthMonth){
      const birthMonth = this.calculationInput.personalData.birthMonth;
      const birthYear = this.calculationInput.personalData.birthYear;
      const ageYear: number = new Date().getFullYear() - birthYear;
      if (ageYear < 55 || (ageYear === 55 && months[birthMonth] > 11))
        return false;
      else return true
    }
    return false
  }
}
