import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionPanelComponent {
  @Input() panelOpenState: boolean = false;
  @Input() enabled: boolean = false;

  @Output() panelOpenStateChange = new EventEmitter<boolean>();

  togglePanel(target: boolean) {
    this.panelOpenState = target;
    this.panelOpenStateChange.emit(target);
  }

}
