import { Component } from '@angular/core';

@Component({
  selector: 'app-deferred-pension-amount-info',
  templateUrl: './deferred-pension-amount-info.component.html',
  styleUrls: ['./deferred-pension-amount-info.component.scss']
})
export class DeferredPensionAmountInfoComponent {

}
