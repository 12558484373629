import { Component } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { TermsAndConditionsComponent } from '../dialogs/terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  public agreedToTerms: boolean = false;
  public nextPanel: boolean = false;
  public isPanelOpen: boolean = true;
  constructor(
    public appService: AppService, 
    public dialog: MatDialog
    ){}

  public getStartedClick(){
    this.appService.welcomeExpanded = false;
    this.appService.personalDataExpanded = true;
    this.appService.personalDataEnabled = true;
  }

  public openTermsAndConditionsDialog(): void {
    const dialogRef = this.dialog.open(TermsAndConditionsComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  check(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }
}
