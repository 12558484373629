<div class="grid page-break-after">
  <div>Personal Data</div>
  <div>Your month and year of birth</div>
  <div><app-dob-info></app-dob-info></div>
  <div>Your spouse’s month & year of birth?</div>
  <div><app-spouse-info></app-spouse-info></div>
  <div>Do you currently have a spouse?</div>
  <div><app-spouse-dob-info></app-spouse-dob-info></div>
</div>

<div class="grid">
  <div>Statement Data</div>
  <div>Month & year you joined the AMA family</div>
  <div><app-join-date-info></app-join-date-info></div>
  <div>Total credited service as of December 31, 2022</div>
  <div class="page-break-after"><app-credited-service-info></app-credited-service-info></div>
  <div>Annualized pensionable earnings in 2022</div>
  <div><app-pensionable-earnings-info></app-pensionable-earnings-info></div>
  <div>Annual DB pension earned as of December 31, 2022</div>
  <div class="page-break-after"><app-db-pension-info></app-db-pension-info></div>
  <div>Annual DB pension earned as of December 31, 2022</div>
  <div><app-db-pension-info></app-db-pension-info></div>
  <div>Do you have an annual DB deferred pension?</div>
  <div><app-deferred-pension-info></app-deferred-pension-info></div>
  <div>Annual DB deferred pension</div>
  <div>
    <app-deferred-pension-amount-info></app-deferred-pension-amount-info>
  </div>
  <div>Do you have a relationship breakdown offset?</div>
  <div>
    <app-relationship-offset-flag-info></app-relationship-offset-flag-info>
  </div>
  <div>Relationship breakdown offset</div>
  <div>
    <app-relationship-offset-amount-info></app-relationship-offset-amount-info>
  </div>
</div>

<div class="grid">
  <div>Assumptions</div>
  <div>How much do you expect your base salary to grow each year?</div>
  <div><app-salary-growth-info></app-salary-growth-info></div>
  <div>What is your planned retirement age?</div>
  <div><app-planned-retirement-info></app-planned-retirement-info></div>
  <div>If you join the New Group RRSP…</div>
  <div><app-rrsp-contribution-info></app-rrsp-contribution-info></div>
  <div>Investment Risk</div>
  <div><app-investment-risk-info></app-investment-risk-info></div>
  <div>Disclaimer</div>
  <div><app-disclaimer></app-disclaimer></div>
</div>

<div class="grid">
  <div>Comparison Results</div>
  <div>DB Pension Plan</div>
  <div><app-current-pension-plan-info></app-current-pension-plan-info></div>
  <div>New Group RRSP</div>
  <div><app-new-group-rrsp-info></app-new-group-rrsp-info></div>
</div>