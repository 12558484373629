<p>
    Keep in mind, there are very important differences in the way each plan
    delivers this estimated income. Under the new Group RRSP, the income is
    based on estimated AMA contributions (and your own contributions), which may
    grow or shrink with future investment performance. We have used a reasonable
    investment return assumption based on your chosen risk level, but this
    return is not guaranteed.
  </p>
  <p>
    At retirement, you may withdraw money as needed from your Group RRSP (within
    applicable tax rules) or you may purchase a life annuity, which converts
    your savings to a regular income for your lifetime. Purchasing a life
    annuity eliminates the risk of outliving your savings. This tool assumes
    that you purchase a life annuity and it uses a reasonable interest rate to
    calculate the resulting income, but this rate could be quite different at
    the time of your retirement.
  </p>