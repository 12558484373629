<p>
  your earnings are used to estimate your pension income under the Defined Benefit pension
  plan. The tool starts with the annualized pensionable earnings as of your most recent statement
  (usually in the previous calendar year), using the salary growth assumption chosen by you,
  projects your earnings for future years.
</p>
<p>
  You can find your <b>Annual DB pension earned as of the most recent statement year</b> in your Annual Pension
  Statement:
</p>
<div class="dialog-image-wrapper">
  <img src="../../../images/annual-db-dialog.png" alt="" />
</div>