import { Component, Inject, Input, NgZone, PLATFORM_ID, OnChanges } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser } from '@angular/common';
import { CalculatorService } from 'src/app/services/calculator.service';
import { ComparisonResult } from 'src/app/models/ComparisonResult';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-heatmap-chart',
  templateUrl: './heatmap-chart.component.html',
  styleUrls: ['./heatmap-chart.component.scss'],
})
export class HeatmapChartComponent implements OnChanges{
  @Input() calculationInput!: CalculationInput;
  @Input() comparisonResults!: ComparisonResult[];
  private root!: am5.Root;

  private colorMap: { [key: string]: string } = {
    '-10': '#DDE1EF ',
    '-9': '#DDE1EF ',
    '-8': '#DDE1EF',
    '-7': '#d8def2',
    '-6': '#c7d0eb',
    '-5': '#fffbf5',
    '-4': '#fff8ed',
    '-3': '#FFF5E6',
    '-2': '#ffe4b8',
    '-1': '#ffdfab',
    '0': '#FFDCA2',
    '1': '#ffc85e',
    '2': '#fcc04c',
    '3': '#ffbe42',
    '4': '#FDB733',
    '5': '#edab39',
    '6': '#e3a232',
    '7': '#D3952B',
    '8': '#bf8624',
    '9': '#bd8a26',
    '10': '#A6781E'
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    public calculatorService: CalculatorService
  ) {}
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnChanges(): void {
    
    this.browserOnly(() => {
      let root = am5.Root.new('heatmap');

        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            layout: root.verticalLayout,
          })
        );

        // Create axes and their renderers
        var yRenderer = am5xy.AxisRendererY.new(root, {
          visible: true,
          minGridDistance: 20,
          inversed: true,
        });
        yRenderer.grid.template.set('visible', false);

        var yAxis = chart.yAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,

            categoryField: 'riskTolerance',
          })
        );

        var xRenderer = am5xy.AxisRendererX.new(root, {
          visible: false,
          minGridDistance: 10,
          opposite: true,
        });

        xRenderer.grid.template.set('visible', false);

        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: 'fractionalAge',
          })
        );
        
        const data = this.comparisonResults;

        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: 'fractionalAge',
            categoryYField: 'riskTolerance',
            valueField: 'comparisonScore',
          })
        );

        series.columns.template.setAll({
          tooltipHTML:
            '<div style="width:300px;"><span><b>Estimated Annual Income Amounts</b></span><br><table width="100%"><tr></tr><td align="left">GRRSP (funded by you):</td><td align="right">${dbAnnualPension}</td><tr><td align="right"></td></tr><tr><td align="right"></td></tr></table><br><span style="margin-left:auto"></span></div>',
          strokeOpacity: 1,
          strokeWidth: 2,
          width: am5.percent(100),
          height: am5.percent(100),
        });

        series.set('heatRules', [
          {
            target: series.columns.template,
            dataField: 'value',
            customFunction: (sprite: am5.Sprite, min, max, value) => {

              const color = this.colorMap[value.toString()];
              if (color) {
                (sprite as am5.Graphics).set('fill', am5.color(color));
              }
            },
            key: 'fill',
          },
        ]);

        const retirementAge = this.calculationInput.assumptions.retirementAge;

        series.columns.template.adapters.add('fill', function (fill, target) {
          let data = <any>target.dataItem!.dataContext;

          if (data.fractionalAge === retirementAge && data.riskTolerance === 'moderate') {
            target.showTooltip();
            return am5.color('#7AC143');
          }
          return fill;
        });

        series.data.setAll(data);

        yAxis.data.setAll([
          { riskTolerance: 'very low' },
          { riskTolerance: 'low' },
          { riskTolerance: 'moderate' },
          { riskTolerance: 'high' },
          { riskTolerance: 'very high' },
        ]);

        const minFractionalAge = Math.min(...data.map(item => item.fractionalAge));
        const maxFractionalAge = Math.max(...data.map(item => item.fractionalAge));

        const xAxisData = [];
        for (let age = minFractionalAge; age <= maxFractionalAge; age++) {
          xAxisData.push({ fractionalAge: age });
        }

        xAxis.data.setAll(xAxisData);
      });
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
