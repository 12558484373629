import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private printSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public printClick$: Observable<boolean> = this.printSubject.asObservable();

  private isWelcomeExpanded: boolean = true;
  private isPersonalDataExpanded: boolean = false;
  private isStatementDataExpanded: boolean = false;
  private isAssumptionsExpanded: boolean = false;
  private isNextStepsExpanded: boolean = false;
  private isComparisonExpanded: boolean = false;

  private isWelcomeEnabled: boolean = true;
  private isPersonalDataEnabled: boolean = false;
  private isStatementDataEnabled: boolean = false;
  private isAssumptionsEnabled: boolean = false;
  private isNextStepsEnabled: boolean = false;
  private isComparisonEnabled: boolean = false;

  constructor() {}

  public set isPrint(isPrint: boolean){
    this.printSubject.next(isPrint);
  }

  public get isPrint(): boolean{
    return this.printSubject.value;
  }

  set personalDataExpanded(expandState: boolean) {
    this.isPersonalDataExpanded = expandState;
  }

  get personalDataExpanded() {
    return this.isPersonalDataExpanded;
  }

  set welcomeExpanded(expandState: boolean) {
    this.isWelcomeExpanded = expandState;
  }

  get welcomeExpanded() {
    return this.isWelcomeExpanded;
  }

  set statementDataExpanded(expandState: boolean) {
    this.isStatementDataExpanded = expandState;
  }

  get statementDataExpanded() {
    return this.isStatementDataExpanded;
  }

  set assumptionsExpanded(expandState: boolean) {
    this.isAssumptionsExpanded = expandState;
  }

  get assumptionsExpanded() {
    return this.isAssumptionsExpanded;
  }

  set nextStepsExpanded(expandState: boolean) {
    this.isNextStepsExpanded = expandState;
  }

  get nextStepsExpanded() {
    return this.isNextStepsExpanded;
  }

  set comparisonExpanded(expandState: boolean) {
    this.isComparisonExpanded = expandState;
  }

  get comparisonExpanded() {
    return this.isComparisonExpanded;
  }

  set personalDataEnabled(expandState: boolean) {
    this.isPersonalDataEnabled = expandState;
  }

  get personalDataEnabled() {
    return this.isPersonalDataEnabled;
  }

  set welcomeEnabled(expandState: boolean) {
    this.isWelcomeEnabled = expandState;
  }

  get welcomeEnabled() {
    return this.isWelcomeEnabled;
  }

  set statementDataEnabled(expandState: boolean) {
    this.isStatementDataEnabled = expandState;
  }

  get statementDataEnabled() {
    return this.isStatementDataEnabled;
  }

  set assumptionsEnabled(expandState: boolean) {
    this.isAssumptionsEnabled = expandState;
  }

  get assumptionsEnabled() {
    return this.isAssumptionsEnabled;
  }

  set nextStepsEnabled(expandState: boolean) {
    this.isNextStepsEnabled = expandState;
  }

  get nextStepsEnabled() {
    return this.isNextStepsEnabled;
  }

  set comparisonEnabled(expandState: boolean) {
    this.isComparisonEnabled = expandState;
  }

  get comparisonEnabled() {
    return this.isComparisonEnabled;
  }
}
