<mat-expansion-panel
  class="mat-elevation-z0"
  [disabled]="!enabled"
  [(expanded)]="panelOpenState"
  (opened)="togglePanel(true)"
  (closed)="togglePanel(false)"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <ng-content select="[header]"> </ng-content>
      <span *ngIf="!enabled" class="mat-expansion-indicator__disabled noprint"></span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
