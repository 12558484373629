import { Component } from '@angular/core';

@Component({
  selector: 'app-deferred-pension-info',
  templateUrl: './deferred-pension-info.component.html',
  styleUrls: ['./deferred-pension-info.component.scss']
})
export class DeferredPensionInfoComponent {

}
