import { AccessService } from './services/access.service';
import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { CalculatorComponent } from './calculator/calculator.component';

const canAccessSite: CanActivateFn =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const canAccess = inject(AccessService).canActivate(route.queryParams.ref);
  return canAccess;
};

const routes: Routes = [
  { path: '', component: CalculatorComponent, canActivate: [canAccessSite] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
