import { Component } from '@angular/core';

@Component({
  selector: 'app-investment-risk-info',
  templateUrl: './investment-risk-info.component.html',
  styleUrls: ['./investment-risk-info.component.scss']
})
export class InvestmentRiskInfoComponent {

}
