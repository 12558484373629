<p>
  If you choose to switch to the new Group RRSP, the “commuted value” of
  your DB pension as of January 1, 2024 will be transferred to your new Group
  RRSP account, subject to tax limits for RRSP transfers.
</p>

<p>
  The commuted value is an estimate of the lump sum present value of your
  pension from the current DB pension plan. It is calculated using assumptions
  about future interest rates, your life expectancy, and (if applicable) the
  life expectancy of your spouse. Commuted value calculations are performed by
  the plan’s actuary. You can see the commuted value of your DB pension as of
  December 31, 2022 on your Annual Pension Statement.
</p>

<p>
  The degree to which your DB commuted value can be transferred to the new Group
  RRSP is subject to tax limits on RRSP transfers. Refer to your 2022 Annual
  Pension Statement for more details.
</p>

<p>
  Once added to your new Group RRSP account, your DB transfer value is invested the same as Group RRSP contributions
  from AMA and you, only it will be in a locked-in retirement account (LIRA).
</p>