<app-expansion-panel [(panelOpenState)]="appService.assumptionsExpanded" [enabled]="appService.assumptionsEnabled">
  <div class="panel_header" header>
    <img src="../../../images/AMA_icon_assumptions.svg" />
    <h1>Your assumptions</h1>
  </div>
  <p class="noprint">*All fields are required</p>
  <form [formGroup]="assumptionsFG" #form="ngForm" (ngSubmit)="openResultsDialog()">
    <label class="info">How much do you expect your base salary to grow each year?
      <img src="../images/icon.svg" (click)="openSalaryGrowthInfoDialog()" /></label>
    <mat-form-field>
      <mat-label>Expected salary growth each year</mat-label>
      <input matInput formControlName="salaryGrowth" />
      <span matSuffix>&nbsp;%</span>
      <mat-error *ngIf="
          assumptionsFG.controls.salaryGrowth.hasError('min') ||
          assumptionsFG.controls.salaryGrowth.hasError('max')
        ">
        Your expected annual salary growth must be between 0% and 4%.
      </mat-error>
      <mat-error *ngIf="assumptionsFG.controls.salaryGrowth.hasError('required')">
        *Required
      </mat-error>
    </mat-form-field>
    <label class="info">What is your planned retirement age?
      <img src="../images/icon.svg" (click)="openPlannedRetirementAgeDialog()" /></label>
    <mat-form-field>
      <mat-label>Planned retirement age</mat-label>
      <input matInput type="number" formControlName="retirementAge" required="true" />
      <span matTextSuffix>years</span>
      <mat-error *ngIf="
          assumptionsFG.controls.retirementAge.hasError('min') ||
          assumptionsFG.controls.retirementAge.hasError('max')
        ">
        Your planned retirement age must be between {{ minRetireYear }} and 71.
      </mat-error>
    </mat-form-field>
    <!--  -->
    <!-- <h3>If you join the New Group RRSP ...</h3>
    <label class="info"
      >What percentage of your base salary will YOU contribute each pay (on top
      of the AMA contribution)?
      <img (click)="openRRSPContributionInfoDialog()" src="../images/icon.svg"
    /></label>
    <p>
      (The tool assumes that you have enough RRSP contribution room to
      accommodate your chosen contribution rate.)
    </p>
    <div class="break"></div>
    <mat-form-field class="expectedRrspGrowth">
      <mat-label>Expected Group RRSP contribution rate</mat-label>
      <input matInput formControlName="rrspContribution" />
      <span matSuffix>&nbsp;%</span>
      <mat-error
        *ngIf="
          assumptionsFG.controls.rrspContribution.hasError('min') ||
          assumptionsFG.controls.rrspContribution.hasError('max')
        "
      >
        Your expected RRSP contribution rate must be between 0% and 18%.
        <b> Note that AMA will match your contribution up to 5%. </b>
      </mat-error>
      <mat-error
        *ngIf="assumptionsFG.controls.rrspContribution.hasError('required')"
      >
        *Required
      </mat-error>
    </mat-form-field>
    <p
      class="contribution-rate__label"
      *ngIf="
        assumptionsFG.controls.rrspContribution.value !== null &&
        getEarningsYear() >= 2023
      "
    >
      <i>Based on your {{ getEarningsYear() }} salary, this represents an annual
      contribution of {{ getAnnualizedPensionableEarnings() | currency }} (or
      {{ getAnnualizedPensionableBiWeeklyEarnings() | currency }} per pay period). The
      dollar amount of your contribution will increase with any increase in your
      base pay. </i>
    </p>
    <p
      class="contribution-rate__label"
      *ngIf="
        assumptionsFG.controls.rrspContribution.value !== null &&
        getEarningsYear() < 2023
      "
    >
      <i>Based on your {{ getEarningsYear() }} earnings, this represents an annual
      contribution of {{ getAnnualizedPensionableEarnings() | currency }} (or
      {{ getAnnualizedPensionableBiWeeklyEarnings() | currency }} per pay period). The
      dollar amount of your contribution will increase with any increase in your
      base pay.</i>
    </p>

    <div
      *ngIf="
        assumptionsFG.value.rrspContribution! >= 0 &&
        assumptionsFG.value.rrspContribution! != null
      "
      class="green-wrapper"
    >
      <p *ngIf="assumptionsFG.value.rrspContribution! > 0">
        <i
          ><b>Congratulations, you'll earn a match!</b> In addition to a 5% base
          contribution, AMA will make a matching contribution of
          {{ getMinValue(getRrspContribution(), 5) }}% for a total AMA
          contribution of {{ getMinValue(+getRrspContribution() + 5, 10) }}%.</i
        >
      </p>
      <p
        *ngIf="
          assumptionsFG.value.rrspContribution! < 5
        "
      >
        <i
          >For an even bigger boost to your retirement savings, consider
          increasing your Group RRSP contribution. AMA will match up to 5%!</i
        >
      </p>
    </div>

    <label class="info"
      >What level of investment risk will you likely take on?
      <img
        (click)="openInvestmentRiskInfoDialog()"
        src="../images/icon.svg"
        alt=""
    /></label>
    <p>
      Generally speaking, lower risk investments are associated with less
      volatility but lower returns in the long run.
    </p> 
  
    <mat-radio-group formControlName="riskTolerance">
      <mat-radio-button [value]="RiskTolerance.VeryLow" class="custom-radio-button">
        Very low risk <br /><span class="lighter-text">(assumed annual return 2%)</span>
      </mat-radio-button>
      <mat-radio-button [value]="RiskTolerance.Low" class="custom-radio-button">
        Low risk <br /><span class="lighter-text">(assumed annual return 3%)</span>
      </mat-radio-button>
      <mat-radio-button [value]="RiskTolerance.Moderate" class="custom-radio-button">
        Moderate risk <br /><span class="lighter-text">(assumed annual return 5%)</span>
      </mat-radio-button>
      <mat-radio-button [value]="RiskTolerance.High" class="custom-radio-button">
        High risk <br /><span class="lighter-text">(assumed annual return 6%)</span>
      </mat-radio-button>
      <mat-radio-button [value]="RiskTolerance.VeryHigh" class="custom-radio-button">
        Very high risk <br /><span class="lighter-text">(assumed annual return 7%)</span>
      </mat-radio-button>
    </mat-radio-group>
    -->
    <!--  -->
    <!-- <div class="red-wrapper" *ngIf="
        assumptionsFG.value.riskTolerance! === RiskTolerance.High ||
        assumptionsFG.value.riskTolerance! === RiskTolerance.VeryHigh
      ">
      <p>
        <strong>Warning!</strong> This option may return overly optimistic results. The assumed
        annual return is based on average historical returns and is not
        promised. High risk investments are typically associated with higher
        returns in the long run but are volatile and may result in significant
        losses during periods of time.
      </p>
      <p *ngIf="isAbove55()">
        Given that you are already in your retirement eligibility window, you
        may want to consider selecting a lower-risk option.
      </p>
    </div> -->
    <!-- <div class="red-wrapper">
      <p>
        <strong>Important!</strong> The assumed annual return is based on average historical
        returns and is not promised. All investments carry risk and outcomes
        cannot be guaranteed.
      </p>
    </div> -->
    <app-notice header="Important!">
      <p>
        This tool assumes that you will stay at AMA until your
        retirement. It uses other assumptions about the future as well,
        including assumptions about inflation and your life expectancy. To learn
        more about these assumptions,
        <a (click)="openDisclaimerDialog()"><u>click here</u></a>.
      </p>
      <br />
      <p>
        Note that the assumptions used by this tool - including those chosen by
        you - can have a very significant impact on your expected retirement
        income. Try changing your assumptions to see the change in your expected
        income.
      </p>
    </app-notice>
    <button mat-flat-button color="primary" [disabled]="!assumptionsFG.valid">
      View your results!
    </button>
  </form>
</app-expansion-panel>