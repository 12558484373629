import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.html',
  styleUrls: ['./next-steps.scss']
})
export class NextStepsComponent {

  constructor(public appService: AppService){}
}
