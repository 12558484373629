import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private screenWidthSubject = new BehaviorSubject<number>(window.innerWidth);
  screenWidth$: Observable<number> = this.screenWidthSubject.asObservable();

  constructor() {
    this.setupResizeListener();
  }

  private setupResizeListener(): void {
    window.addEventListener('resize', () => {
      this.screenWidthSubject.next(window.innerWidth);
    });
  }
}
